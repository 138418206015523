.modal {
    position: absolute; 
    width: 100%;
    background-color: rgba(0,0,0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .modal-content{
        width: 82%;
        position: fixed;
        top: 50%;
        transform: translate(0,-50%);
    }
}

.modal-content {
    max-width: 490px;
    height: 280px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    p {
        color: $primary-2;
        font-family: $font-family-4;
        padding: .5rem 0;
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5em;
    }
}

.modal-header {
    h4 {
        font-family: $font-family-3;
        font-weight: 700;
        color: $primary-2;
        font-size: 1em;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 0.11em;
        line-height: 2.25em;
    }
}

.modal-footer {
    button {
        color: $white;
        background-color: $primary;
    }
}