.libre-Franklin {
  font-family: "Libre Franklin", sans-serif;
}


a{
  color: #FC3381;
}
.MerriWeather {
  font-family: "Merriweather", serif;
}
.application-page {
  font-family: "Libre Franklin", sans-serif;
  background-color: #fafafa;
}
.container-text{
  width: 90%;
}
.text-center.pink-text h5 {
  margin-top: 20px;
  color: #FC3381;
  font-size: 26px;
}
.errorSelect {
  border: 1px #FC3381 solid;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h3 {
  color: #344C73;
  font-size: 36px;
  margin-top: 50px;
  font-family: "Libre Franklin", sans-serif;
  font-weight: bolder;
  padding-left: 10.5%;
}
.why-enrichHer h5{
  position: initial;
  margin: 1% 12%;
}
.why-enrichHer {
margin-top: 100px;
}

.why-enrichHer ul {
  list-style-image: url("../Assets/Icon-checkmark.svg");
}
.accordion .card {
  background-color: #fafafa;
  width: 85%;
  border: none;
  box-shadow: none;
  font-size: 16px;
  padding-top: 0;
  margin-top:1%;
  margin-bottom: 0;

}
.checkbox-same-address #checkbox-label-address{
  margin: 2% 5%;
  position: relative;
  top: 3px;
}
.margin-left{
  padding-left:15px;
}
.hide{
  display:none !important;
  transition: ease-in;
}
.accordion .card .collapse{
  margin-top:0;
  margin-bottom: 0;
}
.accordion .card .collapse.show{
  padding-bottom: 110px;
}

.accordion .card .card-header {
  background-color: #344C73;
  color: #FFFFFF;
}
.arrow-img {
  margin-right: 5px;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.margin-inputs {
  margin-top: 3%;
}
.how_much_input{
  padding: 9px;
}
.profile-information-block,
.finance-block, .marketing-block{
  width: 90%;
  margin: 0 auto;
}
.profile-information-block h6 {
  margin-top: 23px;
}
.terms-and-condition-block label{
  margin-left: 35px;
  position: relative;
    top: 4px;
}
.terms-and-condition-block {
  width:69%;
  margin: 0 auto;
}
.finance-block{
  padding-bottom: 10px;
}

.terms-link{
  text-decoration-line: underline;
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}
input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  top: 0;
  left: 0;
  border: 1px solid #787878	;
  background-color: #FFFF;
}
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 7px;
  height: 10px;
  border: solid #FC3381;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 4.4px;
  left: 8px;
}

.profile-information-block .first-input,
.finance-block .first-input, .marketing-block .first-input {
  margin-top: 12px;
}

.optionals-selects label,
.finance-block label,
.marketing-block label {
  margin: 2% 0;
}

.select__control {
  border: 1px solid#000000 !important;
  border-radius: 0 !important;
  min-height: 35px !important;
}

.errorSelect .select__control {
  border: none !important;
  border-radius: 0 !important;
  min-height: 35px !important;
}

.select__indicator-separator {
  background-color: #ffff !important;
}
.select__placeholder {
  top: 50% !important;
}
.select__menu {
  z-index: 99999 !important;
}
.select__menu-list {
  overflow: hidden !important;
  height: 150px;
  overflow-y: scroll !important;
}
.select__indicator.select__dropdown-indicator {
  position: relative !important;
  top: -3px !important;
}
.select__single-value {
  top: 50% !important;
}
.tooltip-inner {
  font-size: 12px !important;
  cursor: pointer;
}

.title-sub {
  cursor: pointer;
}

.css-1u3x6vx { //Click Wrap signature disclosure
  position: absolute !important;
}

sub{
  color: #FC3381;
  font-size: 20px;
}

.optionals-selects h6 sub {
  float: right;
  color: #FC3381;
}
.state-input {
  width: 128%;
}
.terms-and-condition-block {
  margin-bottom: 20px;
}
.phoneError{
  width: 120%;
}

@media (max-width: 1400px) {
  .accordion .card {
    background-color: #fafafa;
    width: 85%;
    border: none;
    box-shadow: none;
    font-size: 16px;
  }
  .select__indicator-separator {
    background-color: #ffff !important;
  }
  .select__placeholder {
    top: 50% !important;
  }

  .select__indicator.select__dropdown-indicator {
    position: relative !important;
    top: -3px !important;
  }
  .select__single-value {
    top: 50% !important;
  }
  .state-input {
    width: 172%;
    margin-left: -20px;
  }
  .phone_number_input {
    width: 120%;
    margin-left: -20%;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 26px;
    width: 90%;
    margin: 0 auto;
  }

}
@media (max-width: 1200px) {
  .accordion .card {
    background-color: #fafafa;
    width: 85%;
    border: none;
    box-shadow: none;
    font-size: 15px;
  }
  .state-input {
    width: 230%;
    margin-left: -20px;
  }
  .phone_number_input {
    width: 120%;
    margin-left: -20%;
  }
  .why-enrichHer h5{
    position: initial;
    margin: 1% 12%;
  }

  .why-enrichHer ul {
    list-style-image: url("../Assets/Icon-checkmark.svg");
  }
  .terms-and-condition-block {
    margin-bottom: 20px;
  }
  .phoneError{
    width: 100%;
    margin-left: -20%;
  }

}

@media (max-width: 992px){
  .phoneError{
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .why-enrichHer {
    font-size: 20px;
    position: initial;
    text-align: center;
    margin-top: 0;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 230px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    width: 100%;
    margin-left: 0;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 20px;
    width: 83%;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block{
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12, .marketing-block .col-12{
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block {
    margin-bottom: 20px;
  }
  .phoneError{
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 690px) {
  h3 {
    color: #344C73;
    font-size: 26px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }

  .why-enrichHer {
    font-size: 16px;
    position: initial;
    text-align: center;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 201px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    width: 100%;
    margin-left: 0;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 18px;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block{
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12, .marketing-block .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block p{
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  h3 {
    color: #344C73;
    font-size: 26px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }

  .why-enrichHer {
    font-size: 16px;
    position: initial;
    text-align: center;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 130px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    width: 100%;
    margin-left: 0;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 18px;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block{
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12 , .marketing-block .col-12{
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block label{
    position: relative;
    top: -21px;
  }
  .terms-and-condition-block p{
    font-size: 13px;
  }
  .title-text h5{
    font-size: 20px !important;
  }

}

@media (max-width: 425px) {
  .accordion .card {
    width: 100%;
  }
  h3 {
    color: #344C73;
    font-size: 26px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 18px;
  }

  .why-enrichHer {
    font-size: 15px;
    position: initial;
    text-align: center;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 80px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    margin-left: 0;
    width: 100%;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block {
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12, .marketing-block .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block label{
    position: relative;
    top:3px;
    margin-bottom: 30px;
  }
  .terms-and-condition-block p{
    font-size: 13px;
  }
  .title-text h5{
    font-size: 20px !important;
  }

}

@media (max-width: 390px) {
  .accordion .card {
    width: 100%;
  }
  h3 {
    color: #344C73;
    font-size: 26px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 18px;
  }

  .why-enrichHer {
    font-size: 15px;
    position: initial;
    text-align: center;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 50px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    margin-left: 0;
    width: 100%;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block {
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12, .marketing-block .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block label{
    position: relative;
    top: -20px;
    margin-bottom: 20px;
  }
  .terms-and-condition-block p{
    font-size: 13px;
  }
  .title-text h5{
    font-size: 20px !important;
  }


}


@media (max-width: 325px) {
  .accordion .card {
    width: 100%;
  }
  h3 {
    color: #344C73;
    font-size: 26px;
    margin-top: 50px;
    font-family: "Libre Franklin", sans-serif;
    font-weight: bolder;
    padding-left: 10.5%;
  }
  .MerriWeather.text-center.pink-text h4 {
    margin-top: 20px;
    color: #FC3381;
    font-size: 18px;
  }

  .why-enrichHer {
    font-size: 15px;
    position: initial;
    text-align: center;
  }

  .why-enrichHer ul {
    text-align: left;
    padding-left: 50px;
  }

  .margin-inputs input {
    margin-top: 3%;
  }
  .margin-inputs {
    margin-top: 0;
  }
  .state-input {
    width: 100%;
    margin-left: 0px;
  }
  .phone_number_input {
    margin-left: 0;
    width: 100%;
  }
  .profile-information-block,
  .finance-block, .marketing-block, .terms-and-condition-block {
    width: 100%;
  }
  .profile-information-block .col-12,
  .finance-block .col-12, .marketing-block .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .terms-and-condition-block label{
    position: relative;
    top: -21px;
    margin-bottom: 20px;
  }
  .terms-and-condition-block p{
    font-size: 13px;
  }
  .title-text h5{
    font-size: 20px !important;
  }

}
.view-application {
  .container {
    margin: 0 auto;
    max-width: $width-container-app;
  }
  h1 {
    text-align: center;
    padding: 0;
    margin:0;
    color: $primary-2;
    font-weight: 700;
    font-size: 1.5em;
    font-family: $font-family-3;
    text-transform: uppercase;
    letter-spacing: .11em;
  }
  .wrapper-content{
    width: 100%;
    min-height: calc(100vh - 10rem);
  }
}

.list {
  margin: auto;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  color: #14222F;
  font-size: 0.9em;
  padding: 0px 14px;
}