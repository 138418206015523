#modal {
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
    border-radius: 5px; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: $primary;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
    opacity: 1;
    border-radius: 24px;
    &:hover {
      background-color: rgba($primary, 0.9);
    } 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 1000;
}

.account-overlay {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  background: $white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.04);
  color: $primary-2;
  padding: 3rem;
  width: 45.5rem;
  height: 90vh;
  max-height: 48rem;
  overflow: auto;
  border-radius: 5px !important;
  a {
    text-decoration: none;
    font-family: $font-family-4;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $primary-2;
  }
  h1 {
    font-family: $font-family-3;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
  }
  p {
    font-family: $font-family-4;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 1024px) {
    padding: 3rem;
    width: 45.5rem;
  }
  @media screen and (max-width: 800px) {
    padding: 3rem;
    width: 88%;
  }
  @media screen and (max-width: 600px) {
    padding: 2rem 1.5rem;
    width: 95%;
  }
}



